@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 20px;
    background: #4D4D4D;
  }
  
  &::-webkit-scrollbar-track {
    background: #4D4D4D;
    border-radius: 5rem;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 5rem;
  }
}

.btn-services {
  position: absolute;
  bottom: 1.5rem;
  z-index: 2;
}
